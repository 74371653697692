<template>
    <div class="fixed w-full h-full left-0 top-0 bg-slate-800 z-[999] opacity-50 flex flex-wrap justify-center items-center">
        <img src="@/assets/images/loading.svg" alt="Loading">
    </div>
</template>
<script>
    // @ is an alias to /src
    export default {
    name:'loadView',
    components: {
    },
    setup() {
        return {
        }
    }
    }
</script>